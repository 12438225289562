/**
 * @description Top bar style for the component.
 */
export const topBarStyle = {
  display: ["none", "flex"],
  justifyContent: "space-between",
  alignItems: "center",
  px: 3,
  py: 2,
  bg: "onSecondaryFixedVariant",
  color: "primaryFixed",
  fontSize: 1,
}

/**
 * @description Button style for the component.
 */
export const buttonStyle = {
  bg: "transparent",
  color: "onPrimaryContainer",
  alignItems: "center",
  display: "flex",
  mr: 2,
  cursor: "pointer",
  "&:hover": {
    bg: "primary",
    color: "onPrimaryContainer",
    cursor: "pointer",
  },
}

/**
 * @description Address style for the component.
 */
export const addressStyle = {
  display: "flex",
  color: "primaryFixed",

  alignItems: "center",
  "&:hover": {
    textDecoration: "underline",
  },
}

/**
 * @description Link style for the component.
 */
export const linkStyle = {
  textDecoration: "none",
  cursor: "pointer",
  "&:hover": {
    textDecoration: "none",
  },
}
/**
 * @description Container styles.
 */
export const containerStyle = {
  display: "flex",
}
