/**
 * @constant footerStyles
 * @description Styles for the footer component.
 * @type {object}
 */
export const footerStyles = {
  bg: "onSecondaryFixedVariant",
  color: "primaryFixed",
  p: 3, // Applies padding of 3 units
  boxShadow: "0px -2px 2px rgba(0, 0, 0, 0.125)",
}

/**
 * @constant headingStyles
 * @description Styles for the headings in the footer component.
 * @type {object}
 */

export const headingStyles = {
  color: "white",
}

/**
 * @constant gridContainerStyles
 * @description Styles for the grid container in the footer component.
 * @type {object}
 */
export const gridContainerStyles = {
  display: "grid",
  gridTemplateColumns: ["repeat(1, 1fr)", "repeat(2, 1fr)", "repeat(3, 1fr)"],
  gap: 3,
}

/**
 * @constant mainBoxStyles
 * @description Styles for the main box in the footer component.
 * @type {object}
 */
export const mainBoxStyles = {
  textAlign: "center", // Aligns content to the center
  maxWidth: "350px",
}

/**
 * @constant headingBoxStyles
 * @description Styles for the heading box in the footer component.
 * @type {object}
 */
export const headingBoxStyles = {
  mb: 0, // Sets margin bottom to 0
  p: 1, // Applies padding of 1 unit
  minWidth: "250px",
}

/**
 * @constant addressBoxStyles
 * @description Styles for the address box in the footer component.
 * @type {object}
 */
export const addressBoxStyles = {
  px: 3, // Applies horizontal padding of 3 units
}

/**
 * @constant socialLinksBoxStyles
 * @description Styles for the social links box in the footer component.
 * @type {object}
 */
export const socialLinksBoxStyles = {
  display: "flex", // Displays items in a flex container
  justifyContent: "center", // Centers items horizontally
  p: 2, // Applies padding of 3 units
}

/**
 * @constant socialLinkStyles
 * @description Styles for the social link in the footer component.
 * @type {object}
 */
export const socialLinkStyles = {
  p: 1, // Applies padding of 1 unit
  color: "inherit", // Sets the text color to the primary color
}

/**
 * @constant siteMapBoxStyles
 * @description Styles for the site map box in the footer component.
 * @type {object}
 */
export const siteMapBoxStyles = {
  textAlign: "center", // Aligns content to the left
  maxWidth: "350px", // Sets the maximum width to 150px
  mb: 2, // Sets margin bottom to 1
}

/**
 * @constant siteMapHeadingStyles
 * @description Styles for the site map heading in the footer component.
 * @type {object}
 */
export const siteMapHeadingStyles = {
  textAlign: "center", // Aligns the heading text to the center
}

/**
 * @constant siteMapDividerStyles
 * @description Styles for the site map divider in the footer component.
 * @type {object}
 */
export const siteMapDividerStyles = {
  color: "inherit", // Inherits the color from the parent element
}

/**
 * @constant siteMapLinkStyles
 * @description Styles for the site map link in the footer component.
 * @type {object}
 */
export const siteMapLinkStyles = {
  color: "inherit", // Inherits the color from the parent element
}

/**
 * @constant hoursBoxStyles
 * @description Styles for the hours box in the footer component.
 * @type {object}
 */
export const hoursBoxStyles = {
  textAlign: "center", // Aligns content to the center
  maxWidth: "350px", // Sets the maximum width to 150px
  mb: 2, // Sets margin bottom to 1
}
